// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-checkbox .ant-checkbox:hover .ant-checkbox-inner {
  border-color: var(--checkbox-color) !important;
}
.app-checkbox .ant-checkbox-disabled .ant-checkbox-inner {
  background-color: var(--normal-hover-border) !important;
  border-color: var(--normal-hover-border) !important;
}
.app-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--checkbox-color) !important;
  border-color: var(--checkbox-color) !important;
}
.app-checkbox .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #483e04 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/21.checkbox/styles.scss"],"names":[],"mappings":"AAEM;EACE,8CAAA;AADR;AAKM;EACE,uDAAA;EACA,mDAAA;AAHR;AAOM;EACE,kDAAA;EACA,8CAAA;AALR;AAOM;EACE,gCAAA;AALR","sourcesContent":[".app-checkbox {\n    .ant-checkbox:hover {\n      .ant-checkbox-inner {\n        border-color: var(--checkbox-color) !important;\n      }\n    }\n    .ant-checkbox-disabled {\n      .ant-checkbox-inner {\n        background-color: var(--normal-hover-border) !important;\n        border-color: var(--normal-hover-border) !important;\n      }\n    }\n    .ant-checkbox-checked {\n      .ant-checkbox-inner {\n        background-color: var(--checkbox-color) !important;\n        border-color: var(--checkbox-color) !important;\n      }\n      .ant-checkbox-inner::after {\n        border-color: #483e04 !important;\n      }\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
