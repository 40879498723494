import { SET_MODAL_SELECT_PAIR, SET_MODAL_TERMS } from '../constants/modal';

export const setModalSelectPair = (isOpen: boolean) => {
  return {
    type: SET_MODAL_SELECT_PAIR,
    payload: isOpen,
  };
};

export const setModalTerms = (isOpen: boolean) => {
  return {
    type: SET_MODAL_TERMS,
    payload: isOpen,
  };
};
