import { UseReadContractParameters, useAccount, useReadContract } from 'wagmi';
import { useAuth } from '../../libs/rainbow';
import { Address, erc20Abi } from 'viem';

export interface UseErc20BalanceProps {
  tokenAddress: Address;
}

export const useErc20Balance = (
  { tokenAddress }: UseErc20BalanceProps,
  { query, ...options }: UseReadContractParameters<typeof erc20Abi, 'balanceOf'> = {},
) => {
  const { address: userAddress } = useAccount();
  const { isAuthenticated } = useAuth();

  return useReadContract({
    abi: erc20Abi,
    address: tokenAddress,
    functionName: 'balanceOf',
    args: [userAddress!],
    query: {
      enabled: isAuthenticated,
      ...query,
    },
    ...options,
  });
};
