import { createContext as createReactContext, useContext as useReactContext } from 'react';

export interface CreateContextOptions<T> {
  defaultValues?: T;
  hookName?: string;
  providerName?: string;
  strict?: boolean;
}

export type InferUseContextReturn<T extends boolean, V> = T extends true ? V : V | undefined;

/**
 * Creates a context and its associated provider and hook.
 *
 * @param options - The options for creating the context.
 * @param options.defaultValues - The default values for the context.
 * @param options.hookName - The name of the hook function. Defaults to "useContext".
 * @param options.providerName- The name of the provider component. Defaults to "ContextProvider".
 * @returns An array containing the provider component, the hook function, and the context object.
 */
export const createContext = <T>({
  defaultValues,
  hookName = 'useContext',
  providerName = 'ContextProvider',
}: CreateContextOptions<T> = {}) => {
  const Context = createReactContext(defaultValues);
  Context.displayName = providerName;

  const ContextProvider = Context.Provider;

  const useContext = <TStrict extends boolean = true>(
    strict?: TStrict,
  ): InferUseContextReturn<TStrict, T> => {
    const context = useReactContext(Context);

    const safeStrict = strict ?? true;
    if (context === undefined && safeStrict) {
      throw new Error(`${hookName} must be used inside ${providerName}`);
    }

    return context as InferUseContextReturn<TStrict, T>;
  };

  return [ContextProvider, useContext, Context] as const;
};
