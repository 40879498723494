import { useQuery, QueryFunction } from '@tanstack/react-query';
import { apiClient } from 'src/libs/axios';
import { userKeys } from './user-keys';
import { QueryOptions } from 'src/libs/react-query';
import { useAccount } from 'wagmi';
import { useAuth } from 'src/libs/rainbow';

export type GetUserInfoResponse = {
  address: string;
  balance: number;
  chain_id: number;
  connected_at: string;
  created_at: string;
  email: string;
  id: string;
  is_accept_terms: boolean;
  is_vnt: boolean;
  is_vnt_at: string | null;
  referral_code: string;
  referred_by: string | null;
  role: string;
  updated_at: string;
};

export const getUserInfo = async () => {
  const { data } = await apiClient.get(`/v1/user/userInfo`);
  return data ?? [];
};

export type UseGetUserInfoOptions<TData, TError> = QueryOptions<
  GetUserInfoResponse,
  TError,
  TData,
  ReturnType<typeof userKeys.userInfo>
>;

export const useGetUserInfo = <TData = GetUserInfoResponse, TError = Error>(
  userId: string,
  options?: UseGetUserInfoOptions<TData, TError>,
) => {
  const { isAuthenticated } = useAuth();
  return useQuery({
    queryKey: userKeys.userInfo(userId),
    queryFn: getUserInfo,
    enabled: isAuthenticated,
    ...options,
  });
};
