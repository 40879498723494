import { Checkbox, CheckboxProps } from "antd";
import "./styles.scss";
import { CSSProperties } from "react";

export interface IAppCheckbox extends CheckboxProps {
  appDisabled?: boolean;
  customRootClass?: string;
  color?: string;
}

const AppCheckbox = ({ customRootClass, color, appDisabled, ...props }: IAppCheckbox) => {
  const customStyle = color
    ? { "--checkbox-color": color }
    : { "--checkbox-color": "#FBDB28" };

  return (
    <Checkbox
      {...(appDisabled ? { disabled: appDisabled } : {})}
      className={`app-checkbox ${customRootClass}`}
      style={customStyle as CSSProperties}
      {...props}
    />
  );
};

export default AppCheckbox;
