import React, { useCallback, useRef, useState } from 'react';

import { Card, Modal, notification } from 'antd';
import { useAcceptTerm } from 'src/services/user/accept-term';
import { useAppDispatch } from 'src/store';
import { setModalTerms } from 'src/store/actions/modal';
import ButtonContained from '../../02.buttons/ButtonContained';
import AppLabelCheckBox from '../../20.label-checkbox/indes';
import { TermsOfUseContent } from './TermsOfUseContent';

interface SelectPairModalProps {
  isOpen: boolean;
  onClose?: () => void;
}

const ModalTerms: React.FC<SelectPairModalProps> = ({ isOpen, onClose }) => {
  const dispatch = useAppDispatch();
  const [isOver, setIsOver] = useState(false);
  const [isAccept, setIsAccept] = useState(false);
  const [isAcknowledge, setIsAcknowledge] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const userId = localStorage.getItem('userId');
  const acceptTermQuery = useAcceptTerm();

  const handleScroll = useCallback(() => {
    if (contentRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
      const atBottom = scrollTop + clientHeight >= scrollHeight - 10;

      if (atBottom) {
        setIsOver(true);
      }
    }
  }, []);

  const handleLaunch = async () => {
    dispatch(setModalTerms(false));
    await acceptTermQuery.mutate(userId as string);
    notification.success({
      message: 'Terms of Use accepted',
      description: 'You can now trade on VDEX.',
    });
  };

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      footer={null}
      className="z-10 font-montserrat"
      closable={false}
    >
      <div
        className={`terms-of-use text-element-primary flex flex-col gap-6 justify-center items-center`}
      >
        <div className="flex flex-col w-full md:gap-0 gap-2">
          <span className="md:text-2xl text-xl font-semibold text-center font-Transducer">
            ACCEPT TERMS OF USE
          </span>
          <span className="text-letter-pale md:text-base text-sm text-center">
            Read and accept the Terms of Use.
          </span>
        </div>
        <Card className="terms-card w-full relative">
          <div
            className="bottom-fade absolute left-[16px] bottom-[16px] h-[60px]"
            style={{
              display: isOver ? 'none' : 'block',
              background: 'linear-gradient(rgba(255, 255, 255, 0), #221E1C)',
            }}
          />
          <div
            ref={contentRef}
            onScroll={handleScroll}
            className="terms-content-wrapper max-h-[200px] overflow-y-auto"
          >
            <div className="terms-content text-letter-primary flex flex-col gap-[12px]">
              <span className="font-medium text-base">Terms of Use</span>
              <div className="text-letter-pale">{<TermsOfUseContent />}</div>
            </div>
          </div>
        </Card>
        <div className="flex flex-col gap-4 w-full">
          <AppLabelCheckBox
            onChange={(e) => {
              setIsAccept(e.target.checked);
            }}
            appDisabled={!isOver}
            textRightPlace
            label="I accept the Terms of Use."
            customRootClass="!font-medium w-full text-sm"
          />
          <AppLabelCheckBox
            onChange={(e) => {
              setIsAcknowledge(e.target.checked);
            }}
            appDisabled={!isOver}
            textRightPlace
            label="I acknowledge that I am trading with real money."
            customRootClass="!font-medium w-full text-sm"
          />
        </div>
        <div className="flex flex-col w-full gap-2">
          <ButtonContained
            disabled={!isAccept || !isAcknowledge}
            onClick={handleLaunch}
            className="button-confirm"
          >
            <p className="font-semibold md:text-[16px] text-sm uppercase">{'Launch VDEX'}</p>
          </ButtonContained>
        </div>
      </div>
    </Modal>
  );
};

export default ModalTerms;
