import { combineReducers } from 'redux';
import { pairReducer } from './reducers/pairs';
import { themeReducer } from './reducers/theme';
import { modalReducer } from './reducers/modal';

const appReducer = combineReducers({
  theme: themeReducer,
  pairs: pairReducer,
  modal: modalReducer,
});

export const rootReducer = (state: any, action: any) => appReducer(state, action);
