import { useMutation, useQueryClient } from '@tanstack/react-query';
import { setAuthTokens } from 'src/helpers/storage';
import { apiClient } from 'src/libs/axios';
import { MutationOptions } from 'src/libs/react-query';
import { useAccount } from 'wagmi';
import { authKeys } from './auth-keys';

export interface LoginDto {
  signature: string;
  address: string;
  message: string;
  referrerCode?: string;
}

export interface LoginResponse {
  accessToken: string;
  refreshToken: string;
}

export const login = async (loginDto: LoginDto) => {
  const response = await apiClient.post<LoginResponse>('/api/v1/auth/login', loginDto, {
    baseURL: process.env.REACT_APP_AUTH_SERVICE_ENDPOINT,
  });

  return response.data;
};

export type UseLoginOptions<TError = Error, TContext = unknown> = MutationOptions<
  LoginResponse,
  TError,
  LoginDto,
  TContext
>;

export const useLogin = <TError = Error, TContext = unknown>(
  options?: UseLoginOptions<TError, TContext>,
) => {
  const { address: userAddress } = useAccount();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: login,
    onSuccess(data) {
      setAuthTokens(data);

      return queryClient.invalidateQueries({
        queryKey: authKeys.authenticated(userAddress!),
      });
    },
    ...options,
  });
};
