import { LoadingOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, ModalProps, notification, Typography } from 'antd';
import FormItem from 'antd/es/form/FormItem';

type AccessCodeModalProps = Pick<ModalProps, 'open' | 'onClose' | 'onOk'>;

const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff', marginLeft: 8 }} spin />;

export const AccessCodeModal = ({ open, onClose, onOk }: AccessCodeModalProps) => {
  const [form] = Form.useForm();

  //   const [form] = Form.useForm();
  //   const [inputValue, setInputValue] = useState(1);

  //   const onChange: InputNumberProps['onChange'] = (newValue) => {
  //     setInputValue(newValue as number);
  //   };

  const closeModal = (e: React.SyntheticEvent<Element, Event>) => {
    const enteredValue = form.getFieldValue('code');

    if (enteredValue.toLowerCase() === 'seamless') {
      notification.success({
        message: 'Success',
        description: 'Enjoy instant trading!',
        placement: 'bottomRight',
        style: { fontFamily: 'Montserrat' },
      });
      localStorage.setItem('accesscode', 'true');

      onClose!(e);
    } else {
      notification.error({
        message: 'Code is wrong',
        description: 'Please try again with another code',
        placement: 'bottomRight',
        style: { fontFamily: 'Montserrat' },
      });
    }
  };

  return (
    <Modal
      className="position-tab-modal"
      centered
      title="Virtual Dex Access Code"
      open={open}
      footer={false}
      styles={{
        mask: {
          backgroundImage: "url('/images/dex-bg.png')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        },
        wrapper: {
          backdropFilter: 'blur(8px)',
          WebkitBackdropFilter: 'blur(8px)',
        },
      }}
    >
      <Form layout="vertical" form={form} onFinish={closeModal} initialValues={{ code: '' }}>
        <Typography.Paragraph>
          {' '}
          Please enter your special code to access instant trading!
        </Typography.Paragraph>
        <FormItem name="code">
          <Input
            styles={{ input: { textAlign: 'end' } }}
            size="large"
            style={{ textAlign: 'end' }}
            prefix="What is the secret word?"
            onChange={(e) => form.setFieldValue('size', e.target.value)}
          />
        </FormItem>
        <Button block htmlType="submit" size="large" type="primary">
          Confirm
        </Button>
      </Form>
    </Modal>
  );
};
