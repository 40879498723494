import { useQueryClient } from '@tanstack/react-query';
import { removeAuthTokens } from 'src/helpers/storage';
import { useEffect } from 'react';
import { useLogout } from 'src/services/auth/logout';
import { userKeys } from 'src/services/user/user-keys';

export const useTabManager = () => {
  const logoutMutation = useLogout({ onSettled: removeAuthTokens });
  const queryClient = useQueryClient();

  useEffect(() => {
    const channel = new BroadcastChannel('tab_channel');

    channel.postMessage({ type: 'open' });

    const handleTabMessage = async (event: any) => {
      if (event.data.type === 'open') {
        logoutMutation.mutate();

        await queryClient.resetQueries({
          queryKey: userKeys.all,
        });
      }
    };

    channel.addEventListener('message', handleTabMessage);

    return () => {
      channel.close();
    };
  }, []);
};
