import { apiClient } from 'src/libs/axios';

export interface RefreshTokenDto {
  accessToken: string;
  refreshToken: string;
}

export interface RefreshTokenResponse {
  accessToken: string;
  refreshToken: string;
}

export const refreshTokens = async (refreshTokenDto: RefreshTokenDto) => {
  const response = await apiClient.post<RefreshTokenResponse>(
    '/api/v1/auth/refresh-token',
    refreshTokenDto,
    {
      baseURL: process.env.REACT_APP_AUTH_SERVICE_ENDPOINT,
    },
  );

  return response.data;
};
