import { useVaultAddress } from 'src/hooks/useVaultAddress';
import { Address, erc20Abi } from 'viem';
import { UseReadContractParameters, useAccount, useReadContract } from 'wagmi';

export interface UserErc20AllowanceVariables {
  tokenAddress: Address;
  contractAddress: Address;
}

export const erc20AllowanceQueryOptions = (
  {
    tokenAddress,
    userAddress,
    contractAddress: vaultAddress,
  }: UserErc20AllowanceVariables & { userAddress: Address },
  { query, ...options }: UseReadContractParameters<typeof erc20Abi, 'allowance'> = {},
) => {
  return {
    abi: erc20Abi,
    address: tokenAddress,
    functionName: 'allowance',
    args: [userAddress!, vaultAddress],
    query: {
      enabled: !!userAddress,
      ...query,
    },
    ...options,
  } as const;
};

export const useErc20Allowance = (
  { tokenAddress, contractAddress }: UserErc20AllowanceVariables,
  options?: UseReadContractParameters<typeof erc20Abi, 'allowance'>,
) => {
  const { address: userAddress } = useAccount();

  return useReadContract(
    erc20AllowanceQueryOptions(
      {
        tokenAddress,
        userAddress: userAddress!,
        contractAddress,
      },
      options,
    ),
  );
};
