import { MutateOptions } from '@tanstack/react-query';
import {
  Abi,
  ContractFunctionArgs,
  ContractFunctionName,
  TransactionExecutionError,
  UserRejectedRequestError,
} from 'viem';
import { Config } from 'wagmi';
import { WriteContractVariables as WagmiWriteContractVariables } from 'wagmi/query';

export type WriteContractVariables<
  TAbi extends Abi,
  functionName extends ContractFunctionName<TAbi, 'nonpayable' | 'payable'>,
> = WagmiWriteContractVariables<
  TAbi,
  functionName,
  ContractFunctionArgs<TAbi, 'nonpayable' | 'payable', functionName>,
  Config,
  number
>;

export type CommonVariables<
  TAbi extends Abi,
  functionName extends ContractFunctionName<TAbi, 'nonpayable' | 'payable'>,
> = Partial<WriteContractVariables<TAbi, functionName>>;

export const handleTransactionError =
  <TError, TVariables, TContext>(
    onError: (error: TError, variables: TVariables, context: TContext | undefined) => void,
  ) =>
  (error: TError, variables: TVariables, context: TContext | undefined): void => {
    if (
      error instanceof TransactionExecutionError &&
      error.walk((e) => e instanceof UserRejectedRequestError)
    ) {
      console.debug('User rejected transaction');
      return;
    }

    onError(error, variables, context);
  };
