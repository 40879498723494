import { Pair } from '@/src/services/token/get-supported-pairs';
import { SET_CURRENT_PAIR, SET_LIST_PAIR } from '../constants/pairs';

export const setCurrentPair = (pair: Pair) => {
  return {
    type: SET_CURRENT_PAIR,
    payload: pair,
  };
};

export const setListPairs = (pair: Pair[]) => {
  return {
    type: SET_LIST_PAIR,
    payload: pair,
  };
};
