import { useMutation } from '@tanstack/react-query';
import { apiClient } from 'src/libs/axios';
import { MutationOptions } from 'src/libs/react-query';

export interface RequestUpdateChainIdDto {
  chainId: number;
}

export interface UpdateChainIdResponse {
  data: any;
}

export const requestUpdateChainId = async (requestUpdateChainIdDto: RequestUpdateChainIdDto) => {
  const response = await apiClient.post('/v1/user/requestUpdateChainId', requestUpdateChainIdDto);

  return response.data;
};

export type UseRequestUpdateChainIdOptions<TError = Error, TContext = unknown> = MutationOptions<
  UpdateChainIdResponse,
  TError,
  RequestUpdateChainIdDto,
  TContext
>;

export function useRequestUpdateChainId<TError = Error, TContext = unknown>(
  options?: UseRequestUpdateChainIdOptions<TError, TContext>,
) {
  return useMutation({
    mutationFn: requestUpdateChainId,
    ...options,
  });
}
