import React from 'react';

import { Modal } from 'antd';
import { Pair } from 'src/services/token/get-supported-pairs';
import { useAppDispatch, useAppSelector } from 'src/store';
import { setCurrentPair } from 'src/store/actions/pair';
import { useHistory } from 'react-router';
import { PATHS } from 'src/constants/paths';

interface SelectPairModalProps {
  isOpen: boolean;
  onClose: () => void;
  pairs: Pair[];
}

const SelectPairModal: React.FC<SelectPairModalProps> = ({ isOpen, onClose, pairs }) => {
  const dispatch = useAppDispatch();
  const currentPair = useAppSelector((state) => state.pairs.currentPair);
  const history = useHistory();

  const handleSelectPair = (pair: Pair) => {
    dispatch(setCurrentPair(pair));

    history.push(`${PATHS.trade()}/${pair.baseToken}`);
    onClose();
  };

  return (
    <Modal
      title="Select Trading Pair"
      open={isOpen}
      onCancel={onClose}
      footer={null}
      className="z-10 font-montserrat"
    >
      <div className="flex flex-col gap-2">
        {pairs.map((pair) => (
          <button
            key={pair.id}
            className={`p-2 rounded border ${
              currentPair.id === pair.id
                ? 'border-[#00632B] text-[#00632B]'
                : 'border-gray-300 hover:border-[#00632B] hover:text-[#00632B]'
            }`}
            onClick={() => handleSelectPair(pair)}
          >
            {`${pair.baseToken}-USD`}
          </button>
        ))}
      </div>
    </Modal>
  );
};

export default SelectPairModal;
