import { SET_MODAL_SELECT_PAIR, SET_MODAL_TERMS } from '../constants/modal';

interface ModalState {
  modalSelectPair: boolean;
  modalTerms: boolean;
}

const initialState: ModalState = {
  modalSelectPair: false,
  modalTerms: false,
};

export const modalReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_MODAL_SELECT_PAIR:
      return {
        ...state,
        modalSelectPair: action.payload,
      };
    case SET_MODAL_TERMS:
      return {
        ...state,
        modalTerms: action.payload,
      };
    default:
      return state;
  }
};
