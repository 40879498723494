import { ConnectButton } from '@rainbow-me/rainbowkit';
import { Flex, notification } from 'antd';
import { Moon, Sun } from 'lucide-react';
import React, { ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { VirtualIcon } from 'src/assets/icons';
import MenuIcon from 'src/assets/icons/navbar/MenuIcon';
import { PATHS } from 'src/constants/paths';
import { useSocket } from 'src/contexts/SocketContext';
import { setStorageTheme } from 'src/helpers/storage';
import { useDisclosure } from 'src/hooks/useDisclosure';
import { useTabManager } from 'src/hooks/useTabManager';
import useWindowSize from 'src/hooks/useWindowSize';
import { THEME_MODE } from 'src/interfaces/theme';
import { useAuth } from 'src/libs/rainbow';
import { useGetUserInfo } from 'src/services/user/get-user-info';
import { useAppDispatch, useAppSelector } from 'src/store';
import { setModalSelectPair, setModalTerms } from 'src/store/actions/modal';
import { setTheme } from 'src/store/actions/theme';
import { useAccount, useDisconnect } from 'wagmi';
import { Button } from '../../ui/button';
import { DepositModal } from './DepositModal';
import ModalTerms from './ModalTerms';
import SelectPairModal from './SelectPairModal';
import SubMenu from './SubMenu';
import './styles.scss';

interface Props {
  isOpenMenuMobile: boolean;
  setIsOpenMenuMobile: (bool: boolean) => void;
}

const AppHeader: React.FC<Props> = ({ isOpenMenuMobile, setIsOpenMenuMobile }) => {
  const { push } = useHistory();
  const userTheme = useSelector((state: any) => state.theme);
  const { isDesktop, width } = useWindowSize();
  const { chainId } = useAccount();
  const { reset, disconnect, disconnectAsync } = useDisconnect();
  useTabManager();
  const dispatch = useAppDispatch();
  const { isUnauthenticated, isAuthenticated } = useAuth();
  const { userBalance, isBalanceSocketInitialized } = useSocket();
  const [depositModalOpen, depositModalActions] = useDisclosure();

  const userId = localStorage.getItem('userId');
  const { data: userInfo } = useGetUserInfo(userId ?? '');

  const listPairs = useAppSelector((state) => state.pairs.listPair);
  const modalSelectPair = useAppSelector((state) => state.modal.modalSelectPair);
  const modalTerms = useAppSelector((state) => state.modal.modalTerms);

  const handleChangeTheme = () => {
    if (userTheme === THEME_MODE.DARK) {
      dispatch(setTheme(THEME_MODE.LIGHT));
      setStorageTheme(THEME_MODE.LIGHT.toLocaleLowerCase());
    } else {
      dispatch(setTheme(THEME_MODE.DARK));
      setStorageTheme(THEME_MODE.DARK.toLocaleLowerCase());
    }
  };

  const renderRightMenuAction = () => {
    return (
      <div className="flex items-center ml-2 ">
        <button className="text-black dark:text-white" onClick={handleChangeTheme}>
          {userTheme === THEME_MODE.DARK ? (
            <Sun className="w-5 h-5 text-white" />
          ) : (
            <Moon className="w-5 h-5 text-black" />
          )}
        </button>
      </div>
    );
  };

  const handleClickLogo = () => {
    push({ pathname: PATHS.default() });
  };

  const handleDepositModal = () => {
    if (isUnauthenticated) {
      notification.info({
        message: 'Wallet Connection',
        description: 'Please connect your wallet first',
        placement: 'bottomRight',
        style: { fontFamily: 'Montserrat' },
      });
    } else {
      depositModalActions.open();
    }
  };

  function BalanceDisplay({
    isAuthenticated,
    userBalance,
    isBalanceSocketInitialized,
    theme,
  }: {
    isAuthenticated: boolean;
    userBalance: number;
    isBalanceSocketInitialized: boolean;
    theme: THEME_MODE;
  }): ReactNode {
    if (!isAuthenticated) {
      return null;
    }

    // if (!isBalanceSocketInitialized) {
    //   return <Skeleton className={`w-24 h-6 bg-gray-200`} />;
    // }

    const balance = userBalance > 0 ? userBalance.toLocaleString() : '0';
    const textColor = theme === THEME_MODE.DARK ? 'text-white' : 'text-black';

    return <h3 className={textColor}>{balance} USD</h3>;
  }

  useEffect(() => {
    if (isAuthenticated && userInfo?.is_accept_terms === false) {
      dispatch(setModalTerms(true));
    }
  }, [isAuthenticated, userInfo, dispatch]);

  return (
    <div
      className={` flex border-b-0  w-[100%] z-30 border-t-0 border-l-0 bg-transparent border-r-0 shadow-lg border-dark-1/70 dark:border-black justify-between items-center p-4  font-semibold  `}
    >
      <div className="flex items-center justify-between w-1/3">
        <button
          className="flex items-center justify-center cursor-pointer"
          onClick={() => handleClickLogo()}
        >
          <img src={VirtualIcon} alt="frac logo" />
          {userTheme === THEME_MODE.DARK ? (
            <span className="text-[28px] ml-2 text-white font-montserrat ">VDEX</span>
          ) : (
            <span className="text-[28px] ml-2  font-montserrat ">VDEX</span>
          )}
        </button>

        <div className="flex">{(isDesktop || width > 1024) && <SubMenu />}</div>
      </div>

      {width > 1024 ? (
        <div className="flex items-center justify-around">
          <Flex gap={12} align="center">
            <Button
              className="bg-[#00632B] py-4 min-w-[120px] text-white hover:bg-[#00682B]"
              onClick={handleDepositModal}
            >
              Deposit
            </Button>

            <ConnectButton showBalance={false} accountStatus={'full'} />

            <BalanceDisplay
              isAuthenticated={isAuthenticated}
              userBalance={userBalance}
              isBalanceSocketInitialized={isBalanceSocketInitialized}
              theme={userTheme}
            />
          </Flex>

          {renderRightMenuAction()}
        </div>
      ) : (
        <span
          className="menu-icon-mobile"
          onClick={() => {
            setIsOpenMenuMobile(!isOpenMenuMobile);
          }}
        >
          <MenuIcon color={'#878787'} isOpen={isOpenMenuMobile} />
        </span>
      )}
      <SelectPairModal
        isOpen={modalSelectPair}
        onClose={() => {
          dispatch(setModalSelectPair(false));
        }}
        pairs={listPairs}
      />

      <DepositModal
        key={1}
        open={depositModalOpen}
        onClose={depositModalActions.close}
        onDepositSettle={depositModalActions.close}
      />
      <ModalTerms isOpen={modalTerms} />
    </div>
  );
};

export default React.memo(AppHeader);
