import { VdexMobile } from 'src/assets/icons';
import React from 'react';

const MobileLayout = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-black">
      <img src={VdexMobile} alt="image" style={{ width: '100%', height: '100%' }} />
    </div>
  );
};

export default MobileLayout;
