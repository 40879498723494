import { emitChanges } from 'src/hooks/useLocalStorage';

export const themeIAO = 'theme';
export const userThemeIAO = 'user_theme';
export const accessTokenIAO = 'access_token';
export const refreshTokenIAO = 'refresh_token';

export function getStorageUserTheme() {
  return localStorage.getItem(userThemeIAO);
}

export function setStorageTheme(theme: string) {
  localStorage.setItem(themeIAO, theme);
  localStorage.setItem(userThemeIAO, theme);

  emitChanges(themeIAO);
  emitChanges(userThemeIAO);
}

export function getAccessToken() {
  return localStorage.getItem(accessTokenIAO);
}

export function getRefreshToken() {
  return localStorage.getItem(refreshTokenIAO);
}

export function setAuthTokens({
  accessToken,
  refreshToken,
}: {
  accessToken: string;
  refreshToken: string;
}) {
  localStorage.setItem(accessTokenIAO, accessToken);
  localStorage.setItem(refreshTokenIAO, refreshToken);

  emitChanges(accessTokenIAO);
  emitChanges(refreshTokenIAO);
}

export function removeAuthTokens() {
  localStorage.removeItem(accessTokenIAO);
  localStorage.removeItem(refreshTokenIAO);

  emitChanges(accessTokenIAO);
  emitChanges(refreshTokenIAO);
}

export function removeAccessToken() {
  localStorage.removeItem(accessTokenIAO);

  emitChanges(accessTokenIAO);
}
