import { useCallback, useMemo, useSyncExternalStore } from 'react';
import { NonUndefinedGuard } from 'src/types/utils';

export type Listerner = () => void;

const listeners = new Map<string, Listerner[]>();

export const emitChanges = (key: string) => {
  const keyListeners = listeners.get(key);

  if (!keyListeners) {
    return;
  }

  keyListeners.forEach((listener) => {
    listener();
  });
};

export interface UseLocalStorageOptions<T> {
  serialize?: (value: T) => string;
  deserialize?: (text: string) => T;
}

export function useLocalStorage<T>(
  key: string,
  fallbackValue: NonUndefinedGuard<T>,
  options?: UseLocalStorageOptions<T>,
): [T, (value: NonUndefinedGuard<T>) => void, () => void];

export function useLocalStorage<T>(
  key: string,
  fallbackValue?: T,
  options?: UseLocalStorageOptions<T>,
): [T | null, (value: T) => void, () => void];

/**
 *
 * @param key The key under which the value is stored in local storage.
 * @param fallbackValue The fallback value to be used if the local storage item is not found if deserialization fails.
 * @param options useLocalStorage options.
 * @param [options.serialize=JSON.stringify] - Function to serialize the value before storing it in local storage.
 * @param [options.deserialize=JSON.parse] - Function to deserialize the value when retrieving it from local storage.
 */
export function useLocalStorage<T>(
  key: string,
  fallbackValue?: T,
  { serialize = JSON.stringify, deserialize = JSON.parse }: UseLocalStorageOptions<T> = {},
) {
  const subscribe = useCallback(
    (listener: Listerner) => {
      const keyListeners = listeners.get(key) ?? [];
      keyListeners.push(listener);
      listeners.set(key, keyListeners);

      return () => {
        const keyListeners = listeners.get(key) ?? [];
        const newKeyListeners = keyListeners.filter((l) => l !== listener);
        listeners.set(key, newKeyListeners);
      };
    },
    [key],
  );

  const localValue = useSyncExternalStore(subscribe, () => localStorage.getItem(key));

  const setValue = useCallback(
    (value: T) => {
      localStorage.setItem(key, serialize(value));
      emitChanges(key);
    },
    [key, serialize],
  );

  const removeValue = useCallback(() => {
    localStorage.removeItem(key);
    emitChanges(key);
  }, [key]);

  const value = useMemo(() => {
    const _fallbackValue = fallbackValue ?? null;

    if (localValue === null) {
      return _fallbackValue;
    }

    try {
      return deserialize(localValue);
    } catch (error) {
      return _fallbackValue;
    }
  }, [deserialize, fallbackValue, localValue]);

  return [value, setValue, removeValue];
}

/**
 * Pass this function to `deserialize` or `serialize` option if you don't want to serialize or deserialize the value.
 */
export const returnRawValue = (text: string) => text;
