import { Pair } from '@/src/services/token/get-active-pair';
import { SET_CURRENT_PAIR, SET_LIST_PAIR, SET_LIST_TOKEN } from '../constants/pairs';

interface PairState {
  currentPair: Pair;
  listPair: Pair[];
  listToken: string[];
}

const initialState: PairState = {
  currentPair: {} as Pair,
  listPair: [],
  listToken: [],
};

export const pairReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_CURRENT_PAIR:
      return {
        ...state,
        currentPair: action.payload,
      };
    case SET_LIST_PAIR:
      return {
        ...state,
        listPair: action.payload,
      };
    case SET_LIST_TOKEN:
      return {
        ...state,
        listToken: action.payload,
      };
    default:
      return state;
  }
};
