import { useQueryClient } from '@tanstack/react-query';
import { CommonVariables, WriteContractVariables } from 'src/libs/wagmi';
import { vaultAbi } from 'src/web3/vault-contract.abi';
import { Address } from 'viem';
import { Config, UseWriteContractParameters, useAccount, useWriteContract } from 'wagmi';
import { readContractQueryKey } from 'wagmi/query';
import { erc20AllowanceQueryOptions } from './get-allowance';
import { useVaultAddress } from 'src/hooks/useVaultAddress';

const commonVariables = {
  abi: vaultAbi,
  functionName: 'deposit',
} satisfies CommonVariables<typeof vaultAbi, 'deposit'>;

export interface DepositVariables {
  tokenAddress: Address;
  amount: bigint;
  vaultAddress: Address;
}

export const buildDepositVariables = ({
  tokenAddress,
  amount,
  vaultAddress,
}: DepositVariables): WriteContractVariables<typeof vaultAbi, 'deposit'> => {
  return {
    ...commonVariables,
    args: [tokenAddress, amount],
    address: vaultAddress,
  };
};

export type UseDepositOptions<TContext> = UseWriteContractParameters<Config, TContext>;

export const useDeposit = <TContext>({
  mutation,
  ...options
}: UseDepositOptions<TContext> = {}) => {
  const queryClient = useQueryClient();
  const { address: userAddress } = useAccount();
  const vaultAddress = useVaultAddress();

  const approveMutation = useWriteContract({
    mutation: {
      onSuccess: (_, { args }) => {
        const [tokenAddress] = args as WriteContractVariables<typeof vaultAbi, 'deposit'>['args'];
        const allowanceQueryKey = readContractQueryKey(
          erc20AllowanceQueryOptions({
            tokenAddress,
            userAddress: userAddress!,
            contractAddress: vaultAddress,
          }),
        );

        return queryClient.invalidateQueries({ queryKey: allowanceQueryKey });
      },

      ...mutation,
    },
    ...options,
  });

  return approveMutation;
};
